@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Product Sans';
  src: url('../public/fonts/ProductSans-Regular.ttf');
}


*{
  font-family: 'Product Sans';
}